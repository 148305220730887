import React, {useEffect} from 'react'
import video from '../assets/video/404.mp4'
import Footer from '../components/globals/Footer'
import {Link, navigate} from 'gatsby'


const NotFoundPage = ({ data }) => {

  useEffect(() => {
    if(typeof window !== 'undefined'){
      const wrapper = document.querySelector('#gatsby-focus-wrapper main').nextSibling
      
      //console.log(wrapper)
      wrapper.remove()
    }
  })

  return (
    <div>
      <div id="body" className="page page_404"  data-bg-color="bg-black">
        <div class="scene-wrap">
          <div class="scene">
            <div class="wrap">
                <div class="wall wall-right"></div>
                <div class="wall wall-left"></div>   
                <div class="wall wall-top"></div>
                <div class="wall wall-bottom"></div> 
                <div class="wall wall-back"></div>    
            </div>
            <div class="wrap">
                <div class="wall wall-right"></div>
                <div class="wall wall-left"></div>   
                <div class="wall wall-top"></div>
                <div class="wall wall-bottom"></div>   
                <div class="wall wall-back"></div>    
            </div>
          </div>
        </div>
        <div className="cont_404">
          <div className="cont_404--inner">
            <div className="cont_404--innerinner">
              <h1>404<span>page not found</span></h1>
              <div className="title">Edge of the galaxy....</div>
              <div className="text">Unfortunately we can’t take you to a galaxy far far away, but we can launch your business into hyperspace… first we better take you <a href="/" className="test-404"
          onClick={(e) => {
            e.preventDefault()
            if(typeof window !== 'undefined'){
              window.location = '/'
            }
          }}>home</a>...</div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default NotFoundPage
